import vanillaScrollspy from '../../../../Salvagnini.Scrollspy/Resources/Private/Scripts/scrollspy/vanillajs-scrollspy';

document.addEventListener('DOMContentLoaded', function (event) {
    document.querySelectorAll('.adam__scrollprogress__navigation').forEach(function (el) {
        const scrollspy = vanillaScrollspy(el, 0, 'easeInOutQuint', 0);
        scrollspy.init();

        const $firstItem = el.querySelector('.adam__scrollprogress__navigation__item:first-child a');
        $firstItem.classList.add('item--active');
    });
});

document.addEventListener('scroll', function (e) {
    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }

    document.querySelectorAll('.adam__scrollprogress__navigation').forEach(function (el) {
        const $activeLink = el.querySelector('.adam__scrollprogress__navigation__item a.item--active');
        if ($activeLink && isInViewport($activeLink)) {
            let indexS = 0;

            const links = el.querySelectorAll('.adam__scrollprogress__navigation__item a');
            links.forEach((element, index) => {
                if (element.classList.contains('item--active')) {
                    indexS = index;
                    return;
                }
            });

            for (let i = 0; i < links.length; i++) {
                if (i < indexS) { links[i].classList.add('previous__navigation'); }
                else { links[i].classList.remove('previous__navigation'); }
            }
        }
    });
});